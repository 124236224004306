import React, { useEffect, useState } from "react";
import "../index.css";

const CustomCursor: React.FC = () => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: 0,
    y: 0,
  });
  const [isPhoneScreen, setIsPhoneScreen] = useState<boolean>(false);

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setPosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Check if navigator is available (not during SSR or build time)
    if (typeof navigator !== "undefined") {
      setIsPhoneScreen(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (isPhoneScreen) return null;

  return (
    <div
      className="cursor"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
    ></div>
  );
};

export default CustomCursor;
