import me from "../assets/me.webp";
import c1 from "../assets/hal-gatewood-xzzgY__zX8A-unsplash.webp";
import c2 from "../assets/sergey-zolkin-_UeY8aTI6d0-unsplash.webp";
import { Reveal } from "./Reveal";

export default function Ads() {
  const items = [
    {
      id: 1,
      imgSrc: me,
      alt: "Developer",
      title: "Developer",
      subtitle: "Ghazi",
      isMain: false,
      style:
        "absolute inset-0 h-full w-full object-cover opacity-75 transition-opacity group-hover:opacity-50",
      spanText: "",
    },
    {
      id: 2,
      imgSrc: c1,
      alt: "Analysis, Planning, Design",
      title: "",
      subtitle: "",
      isMain: false,
      style:
        "aspect-square w-full object-cover transition duration-500 group-hover:opacity-90",
      spanText: "Analysis, Planning, Design",
    },
    {
      id: 3,
      imgSrc: c2,
      alt: "Passion, creativity, achievement",
      title: "",
      subtitle: "",
      isMain: true,
      style:
        "aspect-square w-full object-cover transition duration-500 group-hover:opacity-90",
      spanText: "Passion, creativity, achievement",
    },
  ];

  return (
    <Reveal>
      <section id="vision">
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 sm:py-12 lg:px-8">
          <header className="text-center">
            <h2 className="text-6xl font-bold text-white uppercase">Vision</h2>
          </header>

          <ul className="mt-8 grid grid-cols-1 gap-4 lg:grid-cols-3">
            {items.map((item) => (
              <li
                key={item.id}
                className={
                  item.isMain
                    ? "lg:col-span-2 lg:col-start-2 lg:row-span-2 lg:row-start-1"
                    : ""
                }
              >
                <a href="#" className="group relative block bg-black">
                  <img
                    src={item.imgSrc}
                    alt={item.alt}
                    className={item.style}
                  />
                  <div className="relative p-4 sm:p-6 lg:p-8">
                    <p
                      style={{ color: "#52CFE4" }}
                      className="text-sm font-medium uppercase tracking-widest"
                    >
                      {item.title}
                    </p>

                    <p className="text-xl font-bold text-white sm:text-2xl">
                      {item.subtitle}
                    </p>

                    <div className="mt-32 sm:mt-48 lg:mt-64">
                      <div className="translate-y-8 transform opacity-0 transition-all group-hover:translate-y-0 group-hover:opacity-100">
                        <p className="text-sm text-white">
                          {/* Additional text can be placed here */}
                        </p>
                      </div>
                    </div>
                    <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
                      {item.spanText && (
                        <span
                          style={{ background: "black", color: "#52CFE4" }}
                          className="mt-1.5 inline-block bg-black px-5 py-3 text-xs font-medium uppercase tracking-wide"
                        >
                          {item.spanText}
                        </span>
                      )}
                    </div>
                  </div>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Reveal>
  );
}
