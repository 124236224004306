import html1 from "../assets/html-5.png";
import css1 from "../assets/css-3.png";
import js from "../assets/jss.png";
import figma from "../assets/figma.png";
import react from "../assets/physics.png";
import tailwind from "../assets/tt.png";
import next from "../assets/nextjs-icon-dark-background.png";
import wp from "../assets/wp.png";
import webflow from "../assets/webflow.png";
import uiux from "../assets/uiux.png";
import git from "../assets/github_media_social_video_icon_123259.png";
import gp from "../assets/00.png";
import jathwah from "../assets/Jathwah.png";
import story from "../assets/story.png";
import quote from "../assets/icon.png";
import wadi from "../assets/wadi.jpg";
import weather from "../assets/weather.png";
import diagnosis from "../assets/diag.png";
import todo from "../assets/todo.png";
import squarespace from "../assets/squarespace_logo_icon_181252.png";
import seo from "../assets/seo.png";
import s24 from "../assets/24.webp";
import visits from "../assets/visitis.webp";
import online from "../assets/online.webp";
import dc from "../assets/dc.webp";
import sql from "../assets/sql1.png";
import node from "../assets/nodejs.png";
import mongo from "../assets/mongodb.png";
import laravel from "../assets/lrarvel.png";
import java from "../assets/java.png";
import python from "../assets/python.png";
import supabase from "../assets/supabase.png";
import strapi from "../assets/strapi.jpg";
import kern from "../assets/kerneltics_logo.jpeg";
import report from "../assets/img1.png";
import Ayman from "../assets/AyamanAvatar.png";
import unkown from "../assets/unlown.jpg";

export const liNav = [
  { li: "About", to: "/" },
  { li: "Vision", to: "#vision" },
  { li: "Services", to: "#services" },

  { li: "Contact", to: "#contact" },
  { li: "Skills", to: "#skills" },
  { li: "Projects", to: "#projects" },
  { li: "My clients", to: "#clients" },
];

export const skills = [
  {
    name: "HTML",
    icon: html1,
  },
  {
    name: "CSS",
    icon: css1,
  },
  {
    name: "JavaScript",
    icon: js,
  },
  {
    name: "Figma",
    icon: figma,
  },
  {
    name: "Tailwind",
    icon: tailwind,
  },
  {
    name: "React",
    icon: react,
  },

  {
    name: "Next",
    icon: next,
  },
  {
    name: "SEO",
    icon: seo,
  },
  {
    name: "WordPress",
    icon: wp,
  },
  {
    name: "Webflow",
    icon: webflow,
  },
  {
    name: "Squarespace",
    icon: squarespace,
  },
  {
    name: "UI/UX",
    icon: uiux,
  },
  {
    name: "GitHub",
    icon: git,
  },
];

export const projects = [
  {
    name: "Discover Makkah",
    desc: "A graduation project website whose goal is to be a visitor’s guide to the city of Makkah, collecting all the instructions, information, services, API's for weather, time, and currency, and places he needs. There is also a chatbot that helps the visitor.",
    img: gp,
    link: "https://drive.google.com/drive/folders/1xyuEE6HOsnqzjFXB77P6gjvvcFxkHGdx?usp=sharing",
  },
  {
    name: "Jathwah",
    desc: "A project for a client website for Jathwah team that contains who Jathwah is, what they offers, what thier mission is, some of their work and clients, contact and location details, and information about them.",
    img: jathwah,
    link: "https://www.jathwah.sa",
  },
  {
    name: "Get your quote",
    desc: "A personal project whose idea is for the user to get a random quote from influential people around the world whenever he presses the button, and he can share the quote on social media.",
    img: quote,
    link: "https://get-quote-test.netlify.app/",
  },

  {
    name: "Share your story",
    desc: "A personal project whose idea is for the user to publish what is on his mind and show us his creativity in his writing. The user can publish a story with title and his real or fake name with the like button, so that the stories with the highest likes will be at the top.",
    img: story,
    link: "https://story-site.vercel.app/",
  },

  {
    name: "Osul website",
    desc: "One of my projects with Kerneltics team where I was the project manager and also the front-end developer for some components, its about the real estate in Saudi Arabia.",
    img: kern,
    link: "https://osol.kerneltics.com/",
  },

  {
    name: "Photographer portfolio ",
    desc: "One of my projects with Kerneltics team where I was the project manager and also the front-end developer for some components, its a portfolio where can the photographer show his work in a professional way.",
    img: kern,
    link: "http://photographer.kerneltics.com/",
  },

  {
    name: "Page for displaying reports",
    desc: "One of my contributions to improving work during the Hajj season is in the Nusuk Cards project, aimed at displaying reports to the team and supervisors more clearly, attractively, and quickly",
    img: report,
    link: "https://reports1.vercel.app/",
  },

  {
    name: "To do list",
    desc: "A personal project aimed at managing your tasks (To-Do-List) add, edit and delete the tasks, and the tasks are divided into completed and unfinished",
    img: todo,
    link: "https://ghazi-todo-app-tset.netlify.app/",
  },
  {
    name: "Makkah Weather",
    desc: "A personal project that connects to the API to review the weather of my city Makkah with time and Arabic / English translation based on the user's choice",
    img: weather,
    link: "https://github.com/Ghazi18",
  },
  {
    name: "Initial diagnosis",
    desc: "A personal project whose idea is a preliminary diagnosis of a patient using artificial intelligence who gives you the results based on your symptoms",
    img: diagnosis,
    link: "https://github.com/Ghazi18",
  },
  {
    name: "Wadi Makkah page",
    desc: "One of the projects during the training period at Wadi Makkah Company was designing and creating its website pages with new ideas and appropriate design",
    img: wadi,
    link: "https://summertrainner.wmitproj.com/#",
  },
  {
    name: "Wadi Makkah dashboard",
    desc: "One of the projects during the training period at Wadi Makkah Company was to design and create a control panel for the administrator through which he can control the page content",
    img: wadi,
    link: "https://github.com/Ghazi18",
  },
];

export const offer = [
  {
    title: "Designe - Code?",
    subtitle: "We design - code it for you ",
    desc: `We design and build for you based on our skills and experience in the field as well as the client's preferences, and turn it into a clean code`,
    img: dc,
  },
  {
    title: "Host - Domain?",
    subtitle: "Publish your website with your name",
    desc: " After programming, we launch your website into the internet world space and reserve a domain of your choice to make your website stand out",

    img: online,
  },
  {
    title: "SEO?",
    subtitle: "Increase website visits on Google",
    desc: "In the long term, we try to raise your site's ranking on search engines such as Google and other search engines in order to increase traffic to your website",
    img: visits,
  },
  {
    title: "Support?",
    subtitle: "Always  up to develop and fix errors",
    desc: "We always follow up with you to update and improve your website based on our observations as well as client feedback and preferences to improve your website's ",
    img: s24,
  },
];

export const usedTo = [
  {
    name: "SQL",
    icon: sql,
  },
  {
    name: "MongoDB",
    icon: mongo,
  },
  {
    name: "Supabase",
    icon: supabase,
  },
  {
    name: "Node js",
    icon: node,
  },
  {
    name: "Laravel",
    icon: laravel,
  },
  {
    name: "Python",
    icon: python,
  },
  {
    name: "Java",
    icon: java,
  },
  {
    name: "Strapi",
    icon: strapi,
  },
];

export const clients = [
  {
    name: "Ayman Alsharif",
    img: Ayman,
    position: "Jathwah CEO",
    opi: "I recently had the pleasure of working with Ghazi to designe and program our website, and we couldn't be more impressed with the results. His attention to detail, creativity, and technical expertise reflect through every step of the process. In addition to offer valuable insights and suggestions to our website.",
  },
  {
    name: "Next client soon !! ...",
    img: unkown,
    position: "########",
    opi: "########################################################################",
  },
];
