import Container from "./components/Container";
import "./App.css";
import { Analytics } from "@vercel/analytics/react";
import CustomCursor from "./components/CustomCursor";
function App() {
  return (
    <div className=" w-full h-full scroll-smooth">
      <CustomCursor />
      <Analytics />
      <Container />
    </div>
  );
}

export default App;
