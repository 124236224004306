import { ContactEmail } from "./ContactEmail";
import { Reveal } from "./Reveal";
export default function Contact() {
  return (
    <>
      <Reveal>
        <div
          id="contact"
          className="flex-row items-center justify-center mt-24"
        >
          <h1 className="text-5xl title-font mb-10 text-white uppercase font-bold flex items-center justify-center ">
            be in touch !
          </h1>
          <div className="text-4xl flex items-center justify-center mx-12">
            {/* GitHub Icon */}
            <a
              href="https://github.com/Ghazi18"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-700 hover:text-gray-500 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="bi bi-github"
              >
                <path
                  fillRule="evenodd"
                  d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.20-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.013 8.013 0 0016 8c0-4.42-3.58-8-8-8z"
                />
              </svg>
            </a>
            {/* Twitter Icon */}
            <a
              href="https://twitter.com/soyghazi"
              target="_blank"
              rel="noopener noreferrer"
              className="ml-4 text-blue-500 hover:text-blue-400 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100"
                height="100"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="bi bi-twitter"
              >
                <path d="M15.46 4.93c-.52.23-1.08.38-1.69.45.61-.36 1.08-.93 1.3-1.61-.57.33-1.2.57-1.87.7-.54-.57-1.31-.93-2.16-.93-1.64 0-2.97 1.33-2.97 2.97 0 .23.03.45.08.66-2.47-.13-4.66-1.31-6.14-3.1-.26.45-.41.97-.41 1.53 0 1.06.54 2 1.36 2.55-.5-.02-.96-.15-1.37-.38v.04c0 1.48 1.05 2.71 2.45 2.98-.26.07-.53.1-.82.1-.2 0-.39-.02-.58-.05.39 1.23 1.53 2.13 2.89 2.15-1.06.83-2.39 1.32-3.84 1.32-.25 0-.49-.02-.73-.04 1.37.88 2.99 1.39 4.74 1.39 5.69 0 8.79-4.72 8.79-8.79 0-.13 0-.26 0-.39.6-.43 1.12-.96 1.53-1.56z" />
              </svg>
            </a>
            <a
              href="mailto:suruji.188@gmail.com"
              className="ml-4 text-red-500 hover:text-red-400 transition duration-300"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="80"
                height="80"
                viewBox="0 0 16 16"
                fill="currentColor"
                className="bi bi-envelope"
              >
                <path d="M0 3.012A3.016 3.016 0 0 1 3.014 0h9.972C15.107 0 16 .893 16 1.988v12.024c0 1.095-.893 1.988-1.988 1.988H3.014A1.988 1.988 0 0 1 1.026 14L0 14 0 3.012zM1 3l7.5 4.5L15 3H1zm0 1.026L8 8l7 3.974V14a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V4.026z" />
              </svg>
            </a>
          </div>
          <ContactEmail />
        </div>
      </Reveal>
    </>
  );
}
