// import heroImg from "../assets/sergey-zolkin-_UeY8aTI6d0-unsplash.jpg";
import heroImg from "../assets/ales-nesetril-Im7lZjxeLhg-unsplash.webp";
import AnimatedPage from "./AnimatedPage";

export default function Hero() {
  return (
    <>
      {/* <section className="text-gray-600 body-font p-6">
        <div className="container mx-auto flex px-5 py-16 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              About
            </h1>
            <p className="mb-8 leading-relaxed text-gray-300">
              Hello there! 👋 I'm Ghazi, a passionate and creative web developer
              with a keen eye for design and a love for crafting digital
              experiences that leave a lasting impression, constantly honing my
              skills and staying on top of industry trends.
            </p>

            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
              What I Do?
            </h1>
            <p className="mb-8 leading-relaxed text-gray-300">
              Im Front-End developer, specialize in building modern and
              responsive websites, ensuring a seamless and enjoyable user
              experience. My expertise includes front-end and design
              technologies,and I'm mainly proficient in using frameworks like
              React.js and Next.js to create dynamic and interactive interfaces,
              as well as using some of the other web development and design
              tools described below.
            </p>
          </div>

          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <img
              className="object-cover object-center rounded"
              alt="hero"
              src={heroImg}
            />
          </div>
        </div>
      </section> */}
      {/* <section className="bg-gray-900 text-white">
        <div className="mx-auto max-w-screen-xl px-4 py-32 lg:flex lg:h-screen lg:items-center">
          <div className="mx-auto max-w-3xl text-center">
            <h1 className="bg-gradient-to-r from-green-300 via-blue-500 to-purple-600 bg-clip-text text-3xl font-extrabold text-transparent sm:text-5xl">
              Understand User Flow.
              <span className="sm:block"> Increase Conversion. </span>
            </h1>

            <p className="mx-auto mt-4 max-w-xl sm:text-xl/relaxed">
              Lorem ipsum dolor sit amet consectetur, adipisicing elit. Nesciunt
              illo tenetur fuga ducimus numquam ea!
            </p>

            <div className="mt-8 flex flex-wrap justify-center gap-4">
              <a
                className="block w-full rounded border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-transparent hover:text-white focus:outline-none focus:ring active:text-opacity-75 sm:w-auto"
                href="/get-started"
              >
                Get Started
              </a>

              <a
                className="block w-full rounded border border-blue-600 px-12 py-3 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring active:bg-blue-500 sm:w-auto"
                href="/about"
              >
                Learn More
              </a>
            </div>
          </div>
        </div>
      </section> */}
      <AnimatedPage>
        <section className="overflow-hidden bg-gray-900 sm:grid sm:grid-cols-2">
          <div className="p-8 md:p-12 lg:px-16 lg:py-24">
            <div className="mx-auto max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
              <h2 className="text-5xl  font-bold text-white  text-left">
                About
              </h2>

              <p className=" text-gray-300 md:mt-4 md:block text-left">
                Hello there! 👋 I'm Ghazi, a passionate and creative web
                developer with a sharp eye for design and a love for crafting
                digital experiences that leave a lasting impression, constantly
                honing my skills and staying on top of industry trends.
              </p>

              <h2 className="text-5xl  font-bold text-white  mt-10 text-left">
                What I Do?
              </h2>

              <p className=" text-gray-300 md:mt-4 md:block text-left">
                Im Front-End developer, specialize in building modern and
                responsive websites, ensuring a seamless and enjoyable user
                experience. My expertise includes front-end and design
                technologies,and I'm mainly proficient in using frameworks like
                React.js and Next.js to create dynamic and interactive
                interfaces, as well as using some of the other web development
                and design tools described below.
              </p>

              <div className="mt-4 md:mt-8">
                <a
                  href="#"
                  style={{ background: "#52CFE4" }}
                  className="inline-block rounded  px-12 py-3 text-sm font-medium text-black transition hover:bg-emerald-700 focus:outline-none focus:ring focus:ring-yellow-400 mt-2 uppercase"
                >
                  Contact for free 😊
                </a>
              </div>
            </div>
          </div>

          <img
            alt="Student"
            src={heroImg}
            className="h-56 w-full object-cover sm:h-full"
          />
        </section>
      </AnimatedPage>
    </>
  );
}
