import React, { useRef, FormEvent } from "react";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "sonner";

const success = () =>
  toast.success("Thank you for your message! I will get back to you soon!");

const error = () =>
  toast.error("Something went wrong. Please try again later!");

export const ContactEmail = () => {
  const form = useRef<HTMLFormElement>(null);

  const sendEmail = (e: FormEvent) => {
    e.preventDefault();

    if (form.current) {
      emailjs
        .sendForm(
          "service_mb31lle",
          "template_tkl8z2w",
          form.current,
          "KgrXqzhmSE7ILTU4K"
        )
        .then(
          (result) => {
            console.log(result.text);
            (e.target as HTMLFormElement).reset();
            success();
          },
          (error) => {
            console.log(error.text);
            error();
          }
        );
    }
  };

  return (
    <div className="flex flex-col items-center justify-center dark mt-16 ">
      <div className="w-full max-w-md bg-gray-800 rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold text-gray-200 mb-4">
          Contact me now!
        </h2>

        <form ref={form} onSubmit={sendEmail} className="flex flex-col">
          <div className="flex flex-row gap-1 flex-wrap">
            <input
              placeholder="Enter your name"
              className="bg-gray-700 w-full text-gray-200 border-0 rounded-md p-1 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="text"
              name="from_name"
              required
            />
            <input
              placeholder="Enter your email"
              className="bg-gray-700 w-full text-gray-200 border-0 rounded-md p-1 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150"
              type="email"
              name="email_id"
              required
            />
          </div>

          <textarea
            className="mt-1 p-2 w-full bg-gray-700 border border-gray-600 rounded-md text-white"
            placeholder="Enter your message"
            name="message"
            required
          ></textarea>

          <Toaster position="bottom-right" />
          <button
            className="bg-gradient-to-r from-green-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-green-600 hover:to-blue-600 transition ease-in-out duration-150"
            type="submit"
            value="Send"
          >
            Send
          </button>
        </form>
      </div>
    </div>
  );
};
