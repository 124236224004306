import { usedTo } from "../data/data";
import { Reveal } from "./Reveal";
export default function UsedTo() {
  return (
    <>
      <Reveal>
        <section id="skills" className="text-gray-600 body-font mt-24">
          <div className="container px-5  mx-auto">
            <div className="flex flex-col text-center w-full mb-14">
              <h1 className="text-6xl title-font mb-4 text-white uppercase font-bold">
                Also used and tried
              </h1>
              <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-300">
                Throughout my educational and programming journey, I have used
                with the my main skills mentioned above..these
              </p>
            </div>
            <div className="flex flex-wrap -m-2">
              {usedTo.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="p-2 lg:w-1/5 md:w-1/2 w-full transition duration-300 ease-in-out hover:scale-95"
                  >
                    <Reveal>
                      <div className="h-full flex items-center border-gray-200 border p-4 rounded-lg">
                        <img
                          alt="team"
                          className="w-16 h-16  object-cover object-center flex-shrink-0  mr-4"
                          src={item.icon}
                        />
                        <div className="flex-grow">
                          <h2 className="text-gray-300 title-font font-medium">
                            {item.name}
                          </h2>
                        </div>
                      </div>
                    </Reveal>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </Reveal>
    </>
  );
}
