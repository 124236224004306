import { Reveal } from "./Reveal";
import { clients } from "../data/data";

export default function Clients() {
  return (
    <div>
      <section id="clients" className="container px-5">
        <div className="mx-auto mb-10">
          <Reveal>
            <h1 className="text-5xl title-font mb-10 text-white uppercase font-bold flex items-center justify-center text-center">
              A pleasure to work with
            </h1>
          </Reveal>
          <Reveal>
            <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
              {clients.map((item, index) => (
                <blockquote
                  key={index}
                  className="rounded-lg bg-gray-100 p-6 shadow-sm sm:p-8 w-full"
                >
                  <div className="flex items-center gap-4">
                    <img
                      alt=""
                      src={item.img}
                      className="size-14 rounded-full object-cover"
                    />
                    <div>
                      <div
                        style={{ color: "#52CFE4" }}
                        className="flex justify-start gap-0.5"
                      >
                        {[...Array(5)].map((_, index) => (
                          <svg
                            key={index}
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <p className="mt-0.5 text-lg font-medium text-gray-900">
                        {item.name}
                      </p>
                      <p className="mt-0.5 text-xs font-medium text-gray-900">
                        {item.position}
                      </p>
                    </div>
                  </div>
                  <p className="mt-4 text-gray-700 text-left">{item.opi}</p>
                </blockquote>
              ))}
            </div>
          </Reveal>
        </div>
      </section>
    </div>
  );
}
