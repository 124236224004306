import mk from "../assets/charlesdeluvio-Lks7vei-eAg-unsplash.webp";
import { Reveal } from "./Reveal";
export default function Mkt() {
  return (
    <Reveal>
    <section className="overflow-hidden rounded-lg shadow-2xl md:grid md:grid-cols-3 w-1/2 h-1/2 mx-auto">
      <img
        alt="Trainer"
        src={mk}
        className="h-32 w-full object-cover md:h-full"
      />

      <div className="p-4 text-center sm:p-6 md:col-span-2 lg:p-8">
        <p className="text-sm font-semibold uppercase tracking-widest text-gray-300">
          Your shortcut to have your own
        </p>

        <h2 className="mt-6 font-black uppercase">
          <span className="text-4xl font-black sm:text-5xl lg:text-6xl text-white">
            WEBSITE
          </span>

          <span className="mt-2 block text-sm text-gray-300"></span>
        </h2>

        <a
          className="mt-8 inline-block w-full bg-black py-4 text-sm font-bold uppercase tracking-widest text-black rounded-sm"
          href=""
          style={{ background: "#52CFE4" }}
        >
          Get it !
        </a>

        <p className="mt-8 text-xs font-medium uppercase text-gray-400"></p>
      </div>
    </section>
    </Reveal>
  );
}
