import { liNav } from "../data/data";
import logo1 from "../assets/logo_black.png";
import { Reveal } from "./Reveal";
import Lottie from "@lottielab/lottie-player/react";
export default function Nav() {
  return (
    <>
      <header className="text-gray-600 body-font bg-gray-900 w-full">
        <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Reveal>
            <a
              className="flex title-font font-medium items-center text-white mb-4 md:mb-0"
              href="/"
            >
              <img
                src={logo1}
                className="w-20 h-15 text-white p-2 bg-gray-800 rounded-full"
              />
              <span className="ml-3 text-xl">GhaziDev Portfolio</span>
            </a>
          </Reveal>

          <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
            {liNav.map((item, index) => {
              return (
                <Reveal>
                  <a
                    key={index}
                    className="mr-5 text-gray-300 text-2xl m-3 hover:text-sky-300 "
                    style={{ cursor: "pointer" }}
                    href={item.to}
                  >
                    {item.li}
                  </a>
                </Reveal>
              );
            })}
          </nav>
          <Reveal>
            {/* <a
              style={{ background: "#52CFE4" }}
              className="inline-flex items-center text-black border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0"
              href="#contact"
            >
              Contact Now!
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-1"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
            </a> */}
            <a href="#contact">
              <Lottie
                src="https://cdn.lottielab.com/l/5t76kQfqCJiona.json"
                autoplay
                className="w-28 h-28 m-0 p-0"
              />
            </a>
          </Reveal>
        </div>
      </header>
    </>
  );
}
