import { offer } from "../data/data";
import { Reveal } from "./Reveal";
export default function Offer() {
  return (
    <section id="services" className="text-gray-600 body-font">
      <div className="container px-5 pt-24 mx-auto">
        <Reveal>
          <div className="flex flex-col text-center w-full mb-14">
            <h1 className="text-6xl title-font mb-4 text-white uppercase font-bold">
              What will I give you?
            </h1>
            <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-gray-300">
              Your website will go through several stages of development with us
            </p>
          </div>
        </Reveal>
        <div className="flex flex-wrap -m-4">
          {offer.map((item, index) => {
            return (
              <div key={index} className="xl:w-1/4 md:w-1/2  p-4 ">
                <div className="bg-gray-100  rounded-lg opacity-80">
                  <img
                    className="h-40 rounded-t w-full object-cover object-center mb-6"
                    src={item.img}
                    alt={item.subtitle}
                  />
                  <div className="px-4 pb-4">
                    <h3
                      style={{ color: "#52CFE4" }}
                      className="tracking-widest  text-xs font-medium title-font"
                    >
                      {item.subtitle}
                    </h3>
                    <h2 className="text-lg text-gray-900 font-medium title-font mb-4">
                      {item.title}
                    </h2>
                    <p className="leading-relaxed text-base">{item.desc}</p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
}
